import React from "react";
import { AuthContext } from "../../provider/AuthProvider";
import LoginView from "../login/LoginView";
import SchemaFormBody, {
  ISchemaFormPropConfig,
  TSchemaFormErrors,
} from "../../components/SchemaFormBody";
import { oas30 } from "openapi3-ts";

import { Alert, Button, Form, Loader, Message, SelectPicker } from "rsuite";
import useCustomers from "../../hooks/useCustomers";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../inc/schema";
import ajv from "../../inc/ajv";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { parseLocationSearch } from "../../inc/data";
import { I18nContext } from "../../provider/I18nProvider";
import {
  ETemplateType,
  getTemplateDefinitions,
} from "../../layout/AppLayout/DashboardTemplateDialog";
import useMes from "../../hooks/useMes";
import { components } from "../../types/openapi";
import { parse } from "date-fns";

import "./index.scss";

const prDashboardRequestSchema = {
  type: "object",
  properties: {
    dashboardName: {
      type: "string",
      minLength: 1,
    },
    customerId: {
      type: "string",
      minLength: 1,
    },
    prDashboardUrl: {
      type: "string",
      minLength: 1,
      format: "url",
      readOnly: true,
    },
    pressReleaseUrl: {
      type: "string",
      minLength: 1,
      format: "url",
      readOnly: true,
    },
    publishDate: {
      type: "string",
      readOnly: true,
    },
  },
  required: [
    "dashboardName",
    "customerId",
    "prDashboardUrl",
    "pressReleaseUrl",
  ],
} as oas30.SchemaObject;

interface IPrDashboardRequest {
  dashboardName: string;
  customerId: string;
  prDashboardUrl: string;
  pressReleaseUrl: string;
  publishDate?: string;
}

const validate = ajv.compile(prDashboardRequestSchema);

const locationSearchData = parseLocationSearch<Partial<IPrDashboardRequest>>(
  window.location.search,
);

const PrDashboardView = () => {
  const { t } = React.useContext(I18nContext);
  const { setCurrentCustomer, updateDashboard } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);
  const customers = useCustomers();
  const mes = useMes();
  const [prDashboardRequest, setPrDashboardRequest] =
    React.useState<IPrDashboardRequest | null>({
      ...getEmptyObject<IPrDashboardRequest>(prDashboardRequestSchema),
      ...locationSearchData,
      customerId: auth?.jwt.currentCustomerLinkId || "",
    });
  const [errors, setErrors] = React.useState<
    TSchemaFormErrors<IPrDashboardRequest>
  >({});

  const customerSelectOptions = React.useMemo(() => {
    if (!auth || !customers || !mes || !mes.length) {
      return null;
    }
    return mes
      .reduce<components["schemas"]["Customer"][]>((prev, me) => {
        me.customerLinkIds.forEach((customerLinkId) => {
          const customer = customers[customerLinkId];
          if (
            customer &&
            customer.active &&
            customer.groupId === auth?.jwt.currentGroupId
          ) {
            prev.push(customer);
          }
        });
        return prev;
      }, [])
      .sort((customerA, customerB) =>
        customerA.customerName.localeCompare(customerB.customerName),
      )
      .map((customer) => ({
        value: customer.customerLinkId,
        label: customer.customerName,
      }));
  }, [auth, customers, mes]);

  const templateDefinitions = React.useMemo(
    () => getTemplateDefinitions(t),
    [t],
  );

  const onSubmit = React.useCallback(() => {
    if (!prDashboardRequest) {
      return;
    }
    const isValid = validate(prDashboardRequest);
    if (!isValid && validate.errors && validate.errors.length) {
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      Alert.error(
        `"${t(validate.errors[0].instancePath.substr(1))}": ${t(
          validate.errors[0].message || "genericErrorMessage",
        )}`,
      );
      return;
    }
    setPrDashboardRequest(null);
    const publishDate = prDashboardRequest.publishDate
      ? parse(prDashboardRequest.publishDate, "yyyy-MM-dd HH:mm:ss", new Date())
      : undefined;
    updateDashboard(
      {
        ...templateDefinitions[ETemplateType.TEMPLATE_TYPE_MONITORING]
          .dashboard,
        name: prDashboardRequest.dashboardName,
        prDashboardUrl: prDashboardRequest.prDashboardUrl,
      },
      {
        pressReleaseUrl: prDashboardRequest.pressReleaseUrl,
        publishDate: publishDate ? publishDate.toISOString() : undefined,
      },
    )
      .then((updatedDashboard) => {
        window.location.href = `https://mijn.deperslijst.com/mediaweb?url=${encodeURIComponent(
          prDashboardRequest.pressReleaseUrl,
        )}&dashboardUrl=${encodeURIComponent(
          `${window.location.origin}/app/dashboards/${updatedDashboard.dashboardId}`,
        )}`;
      })
      .catch((err) => {
        console.log(err);
        Alert.error(
          `Dashboard kon niet worden aangemaakt. Kies een nieuwe, unieke naam`,
        );
        setPrDashboardRequest(prDashboardRequest);
      });
    setErrors({});
  }, [prDashboardRequest, t, templateDefinitions, updateDashboard]);

  const config = React.useMemo<{
    [P in keyof Partial<IPrDashboardRequest>]: ISchemaFormPropConfig;
  }>(
    () => ({
      customerId: {
        formControlProps: {
          accepter: (props) =>
            customerSelectOptions ? (
              <SelectPicker
                {...props}
                placeholder="Kies een media·web klant-account"
                data={customerSelectOptions}
                searchable={false}
                onChange={(customerId) => {
                  if (customerId) {
                    setCurrentCustomer(customerId);
                  }
                  setPrDashboardRequest((prDashboardRequest) =>
                    prDashboardRequest
                      ? {
                          ...prDashboardRequest,
                          customerId,
                        }
                      : prDashboardRequest,
                  );
                }}
              />
            ) : (
              <Loader />
            ),
        },
      },
    }),
    [customerSelectOptions, setCurrentCustomer],
  );

  if (auth?.jwt.userRoles.includes("superuser")) {
    return (
      <Message
        type="error"
        title="Beheerdersaccount gedetecteerd"
        description={
          <div>
            <p>
              Er dient een regulier Mediaweb-account ingelogd te zijn om deze
              koppeling te maken.
            </p>
            <p>
              Klik evt.{" "}
              <a href="/app/users" target="_blank">
                hier
              </a>{" "}
              om naar een regulier gebruikersaccount te wisselen en probeeer het
              daarna nogmaals.
            </p>
          </div>
        }
      />
    );
  }

  if (
    prDashboardRequest &&
    (!prDashboardRequest.prDashboardUrl || !prDashboardRequest.pressReleaseUrl)
  ) {
    return (
      <Message
        type="error"
        title="Er gaat iets mis"
        description={
          <p>
            De aanroep voor deze koppeling is onvolledig. Neem a.u.b. contact op
            met uw accountmanager.
          </p>
        }
      />
    );
  }

  if (!auth) {
    return (
      <div className="pr-dashboard-view">
        <Message
          type="info"
          title="Nog geen klant van Media Info Groep?"
          className="mt-3"
          description={
            <p>
              <a
                href="https://www.mediainfogroep.nl/pr-dashboard-en-media-info-groep/"
                target="_blank"
                rel="noreferrer"
              >
                Klik hier voor meer informatie en een introductie-aanbieding
              </a>
            </p>
          }
        />
        <LoginView />
      </div>
    );
  }

  if (customerSelectOptions && !customerSelectOptions.length) {
    return (
      <Message
        type="error"
        title="Er gaat iets mis"
        description={
          <p>
            Uw gebruiker heeft geen toegang tot klantgegevens.{" "}
            <a href="/logout">Uitloggen</a>
          </p>
        }
      />
    );
  }

  return (
    <div>
      {customerSelectOptions && prDashboardRequest ? (
        <Form onSubmit={onSubmit} className="my-5">
          <SchemaFormBody<IPrDashboardRequest>
            errors={errors}
            schema={prDashboardRequestSchema}
            value={prDashboardRequest}
            onChange={setPrDashboardRequest}
            config={config}
          />
          <Button appearance="primary" onClick={onSubmit}>
            Maak koppeling
          </Button>
        </Form>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default React.memo(PrDashboardView);
