import React from "react";
import { Alert, Button, HelpBlock, Modal } from "rsuite";
import { TSchemaFormErrors } from "../../../components/SchemaFormBody";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import { components } from "../../../types/openapi";
import { Loader } from "rsuite";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import ajv from "../../../inc/ajv";
import axios from "../../../inc/axios";
import { AxiosError } from "axios";
import { I18nContext } from "../../../provider/I18nProvider";
import LabelAutomatorFilterBar from "./LabelAutomatorFilterBar";
import SearchEngineResultsPanel from "../../SearchEngineView/SearchEngineResultsPanel";
import _ from "lodash";
import LabelSelector from "./LabelSelector";
import "./index.scss";
import useLabels from "../../../hooks/useLabels";
import useLabelAutomators from "../../../hooks/useLabelAutomators";
import { IMaartenError } from "../../../types";

interface ILabelAutomatorModalProps {
  show: boolean;
  labelAutomatorId?: string;
  onClose: () => void;
}

type LabelAutomator = components["schemas"]["LabelAutomator"];

const LabelAutomatorSchema = openapi.components.schemas
  .LabelAutomator as oas30.SchemaObject;
const validate = ajv.compile(LabelAutomatorSchema);

const { properties } = LabelAutomatorSchema;
if (!properties) {
  throw new Error("Incomplete label automator schema");
}

const emptySearchEngineRequest = getEmptyObject<
  components["schemas"]["SearchEngineRequest"]
>(openapi.components.schemas.SearchEngineRequest as oas30.SchemaObject);
emptySearchEngineRequest.query.includeMediaWeb = true;
emptySearchEngineRequest.query.includeMediaPrint = true;

const LabelAutomatorModal = ({
  show,
  labelAutomatorId,
  onClose,
}: ILabelAutomatorModalProps) => {
  const { createLabelAutomator } = React.useContext(ApiDataContext);
  const { t } = React.useContext(I18nContext);
  const [labelAutomator, setLabelAutomator] = React.useState<LabelAutomator>();
  const [errors, setErrors] = React.useState<TSchemaFormErrors<LabelAutomator>>(
    {},
  );
  const labels = useLabels();
  const labelAutomators = useLabelAutomators();

  const [testResults, setTestResults] = React.useState<
    components["schemas"]["SearchEngineResults"] | null
  >();
  // eslint-disable-next-line
  const [searchEngineRequest, setSearchEngineRequest] =
    React.useState<components["schemas"]["SearchEngineRequest"]>();

  const labelAutomatorTest = React.useCallback(
    (labelAutomator: components["schemas"]["LabelAutomator"]) => {
      if (!labelAutomator.query.matchText) {
        setTestResults(undefined);
        return;
      }
      setTestResults(null);
      axios
        .request<components["schemas"]["SearchEngineResults"]>({
          method: "post",
          url: "/labelAutomator/test",
          data: labelAutomator,
        })
        .then((res) => {
          setTestResults(res.data);
        })
        .catch((err: AxiosError<IMaartenError>) => {
          // @TODO translation
          Alert.error(
            t(
              err.response?.data.error ||
                "Label zoekopdracht test mislukt, probeer het later nog eens",
            ),
          );
        });
    },
    [t],
  );

  const debouncedLabelAutomatorTest = React.useMemo(
    () =>
      _.debounce(
        (labelAutomator: components["schemas"]["LabelAutomator"]) =>
          labelAutomatorTest(labelAutomator),
        1000,
      ),
    [labelAutomatorTest],
  );

  const formIsDirty = React.useMemo(() => {
    const originalUser =
      labelAutomators && labelAutomatorId
        ? labelAutomators[labelAutomatorId]
        : null;
    return JSON.stringify(originalUser) !== JSON.stringify(labelAutomator);
  }, [labelAutomator, labelAutomatorId, labelAutomators]);

  const onLabelAutomatorChange = React.useCallback(
    (newLabelAutomator: components["schemas"]["LabelAutomator"]) => {
      setLabelAutomator(newLabelAutomator);
      debouncedLabelAutomatorTest(newLabelAutomator);
    },
    [debouncedLabelAutomatorTest],
  );

  const onSubmit = React.useCallback(() => {
    if (!labelAutomator?.labelId) {
      setErrors({
        labelId: "view_LabelAutomatorModal_selectALabel",
      });
      return;
    }

    const isValid = validate(labelAutomator);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      return;
    }

    if (!labelAutomator || !!labelAutomator.labelAutomatorId) {
      return;
    }
    setErrors({});
    createLabelAutomator({
      ...labelAutomator,
      query: {
        ...labelAutomator.query,
        minRelevance: 0,
      },
    })
      .then(() => {
        onClose();
      })
      .catch((err) => {
        Alert.error(t(err.response.data.error || "invalidData"));
      });
  }, [createLabelAutomator, labelAutomator, onClose, t]);

  React.useEffect(() => {
    if (!labelAutomators || !labelAutomatorId) {
      return;
    }

    if (
      labelAutomator &&
      (labelAutomator.labelAutomatorId === labelAutomatorId ||
        (labelAutomatorId === "NEW" && !labelAutomator.labelAutomatorId))
    ) {
      return;
    }

    let newLabelAutomator: LabelAutomator | undefined =
      labelAutomators && labelAutomatorId
        ? labelAutomators[labelAutomatorId]
        : undefined;
    if (!newLabelAutomator) {
      newLabelAutomator = {
        ...getEmptyObject(LabelAutomatorSchema),
      } as LabelAutomator;
      newLabelAutomator.query.includeMediaWeb = true;
      newLabelAutomator.query.includeMediaPrint = true;
    }

    if (!newLabelAutomator) {
      // @TODO translation
      throw new Error("Could not construct label automator for form");
    }
    setLabelAutomator(JSON.parse(JSON.stringify(newLabelAutomator)));
    setTestResults(undefined);
    if (newLabelAutomator.labelAutomatorId) {
      labelAutomatorTest(newLabelAutomator);
    }

    setSearchEngineRequest({
      ...emptySearchEngineRequest,
      query: newLabelAutomator.query,
    });
  }, [labelAutomatorId, labelAutomator, labelAutomators, labelAutomatorTest]);

  React.useEffect(() => {
    if (!show) {
      setLabelAutomator(undefined);
    }
  }, [show]);

  const currentLabel =
    labelAutomator?.labelId && labels ? labels[labelAutomator?.labelId] : null;

  return (
    <Modal
      show={show}
      onHide={onClose}
      className="modal-size-auto label-automator-modal"
    >
      <Modal.Header>
        <div className="row w-100">
          <div className="col align-self-center">
            {labelAutomator ? (
              <>
                <LabelSelector
                  editable={!labelAutomator.labelAutomatorId}
                  label={currentLabel}
                  onChange={(label) => {
                    setLabelAutomator((currentLabelAutomator) => {
                      if (!currentLabelAutomator) {
                        return currentLabelAutomator;
                      }
                      return {
                        ...currentLabelAutomator,
                        title: label.name,
                        labelId: label.labelId as string,
                      };
                    });
                  }}
                />
                {errors && errors.labelId ? (
                  <HelpBlock style={{ color: "red" }}>
                    {t(errors.labelId)}
                  </HelpBlock>
                ) : null}
              </>
            ) : null}
          </div>
          <div className="col-auto">
            {!!labelAutomator?.labelAutomatorId ? null : (
              <Button
                onClick={onSubmit}
                appearance="primary"
                color="blue"
                disabled={!formIsDirty}
              >
                {t("save")}
              </Button>
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="modal-body-no-padding" style={{ height: "100vh" }}>
        <div className="modal__content">
          {searchEngineRequest && labelAutomator ? (
            <>
              <LabelAutomatorFilterBar
                editable={!labelAutomator.labelAutomatorId}
                searchEngineRequest={searchEngineRequest}
                searchTopicIds={labelAutomator.searchTopicIds || []}
                onChange={(newSearchEngineRequest) => {
                  setSearchEngineRequest(newSearchEngineRequest);
                  onLabelAutomatorChange({
                    ...labelAutomator,
                    query: newSearchEngineRequest.query,
                  });
                }}
                onSearchTopicIdsChange={(newSearchTopicIds) => {
                  onLabelAutomatorChange({
                    ...labelAutomator,
                    searchTopicIds: newSearchTopicIds,
                  });
                }}
              />
              {testResults !== undefined ? (
                <SearchEngineResultsPanel
                  hideButton={true}
                  isMoreAvailable={false}
                  isSelectable={false}
                  searchEngineResults={testResults}
                  searchEngineRequest={searchEngineRequest}
                />
              ) : null}
            </>
          ) : (
            <Loader center size="lg" />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LabelAutomatorModal;
